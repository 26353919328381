<!--  -->
<template>
  <div class=''>
    <el-tabs type="border-card">


      <el-tab-pane label="全部消息">
        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column prop="mesTitle" label="标题"></el-table-column>
          <el-table-column prop="mesName" label="发信人"></el-table-column>
          <el-table-column prop="mesKind" label="信息类型"></el-table-column>
          <el-table-column prop="mesTime" label="信息日期"></el-table-column>
          <el-table-column prop="mesCont" label="详细内容">
            <el-button  size="mini" @click="$router.push({ name: 'privateMessage1' })" type="primary">
              查看
            </el-button>
          </el-table-column>
          <el-table-column prop="mesAct" label="操作">
            <template v-slot:default="scope4">
              <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope4.$index, scope4.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>


      <el-tab-pane label="系统通知">
        <el-table
            :data="tableData1"
            style="width: 100%">
          <el-table-column prop="mesTitle" label="标题"></el-table-column>
          <el-table-column prop="mesName" label="发信人"></el-table-column>
          <el-table-column prop="mesKind" label="信息类型"></el-table-column>
          <el-table-column prop="mesTime" label="信息日期"></el-table-column>
          <el-table-column prop="mesCont" label="详细内容">
            <el-button  size="mini" @click="$router.push({ name: 'systemMessage1' })" type="primary">
              查看
            </el-button>
          </el-table-column>
          <el-table-column prop="mesAct" label="操作">
            <template v-slot:default="scope4">
              <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope4.$index, scope4.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>


      <el-tab-pane label="个人消息">
        <el-table
            :data="tableData2"
            style="width: 100%">
          <el-table-column prop="mesTitle" label="标题"></el-table-column>
          <el-table-column prop="mesName" label="发信人"></el-table-column>
          <el-table-column prop="mesKind" label="信息类型"></el-table-column>
          <el-table-column prop="mesTime" label="信息日期"></el-table-column>
          <el-table-column prop="mesCont" label="详细内容">
            <el-button  size="mini" @click="$router.push({ name: 'privateMessage1' })" type="primary">
              查看
            </el-button>
          </el-table-column>
          <el-table-column prop="mesAct" label="操作">
            <template v-slot:default="scope4">
              <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope4.$index, scope4.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

      </el-tab-pane>
      <el-tab-pane label="已发送">
        <el-table
            :data="tableData3"
            style="width: 100%">
          <el-table-column prop="mesTitle" label="标题"></el-table-column>
          <el-table-column prop="mesName" label="发信人"></el-table-column>
          <el-table-column prop="mesKind" label="信息类型"></el-table-column>
          <el-table-column prop="mesTime" label="信息日期"></el-table-column>
          <el-table-column prop="mesCont" label="详细内容">
            <el-button  size="mini" @click="$router.push({ name: 'privateMessage1' })" type="primary">
              查看
            </el-button>
          </el-table-column>
          <el-table-column prop="mesAct" label="操作">
            <template v-slot:default="scope4">
              <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope4.$index, scope4.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <el-button  size="mini" @click="$router.push({ name: 'writeMessage1' })" type="primary" style="margin-top: 25px;font-size: 15px">
      写信
    </el-button>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
//这里存放数据
    return {
      tableData2:[{
        mesTitle: '第一个信息',
        mesName: '王小虎',
        mesKind: '个人消息',
        mesTime: '2022-06-08',
        mesCont:'',
        mesAct:'',
      }],
      tableData1:[{
        mesTitle: '第一个通知',
        mesName: '管理员',
        mesKind: '系统通知',
        mesTime: '2022-06-09',
        mesCont:'',
        mesAct:'',}],
      tableData: [{
        mesTitle: '第一个信息',
        mesName: '王小虎',
        mesKind: '个人消息',
        mesTime: '2022-06-08',
        mesCont:'',
        mesAct:'',
      },{
        mesTitle: '第一个通知',
        mesName: '管理员',
        mesKind: '系统通知',
        mesTime: '2022-06-09',
        mesCont:'',
        mesAct:'',
      }],
    };
  },
//监听属性 类似于data概念
  computed: {},
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {
    handleDelete(index, row) {
      console.log(index, row);
    },
  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>
